import React from "react";
import ReactHighcharts from "highcharts-react-official";
import Highcharts from "highcharts";

export default class ResponseTimeDonut extends React.Component {
  color(time) {
    if (time < 5) {
      return "#50C1BF";
    }
    if (time >= 5 && time < 60) {
      return "#FFB340";
    }
    if (time >= 60) {
      return "#EB5757";
    }
  }
  answered() {
    const minutes = this.props.time / 60;
    return Math.ceil(minutes);
  }

  notAnswered() {
    const minutes = this.props.time / 60;
    if (minutes > 55) {
      return 5;
    }
    return 60 - Math.ceil(minutes);
  }

  render() {
    if (!this.props.time) {
      return (
        <div>
          <img
            src="/img/red-circle.svg"
            alt="no phone response"
            className="img-fluid"
            style={{ maxWidth: "150px" }}
          />
          <br />
        </div>
      );
    }

    let chartTitle = `<div class='text-center'><h4>${this.answered().toLocaleString()}</h4><span class='text-muted'>Minutes</span></div>`;
    Highcharts.setOptions({
      lang: {
        thousandsSep: ",",
      },
    });
    return (
      <ReactHighcharts
        highcharts={Highcharts}
        options={{
          credits: {
            enabled: false,
          },
          chart: {
            type: "pie",
            height: 200,
            width: 200,
            align: "center",
          },
          title: {
            text: chartTitle,
            verticalAlign: "middle",
            useHTML: true,
          },
          plotOptions: {
            pie: {
              dataLabels: {
                enabled: false,
              },
              borderWidth: 3,
            },
            series: {
              animation: false,
            },
          },
          series: [
            {
              type: "pie",
              name: "Minutes",
              innerSize: "80%",
              data: [
                {
                  name: "",
                  y: this.answered(),
                  color: this.color(this.answered()),
                },
                { name: "", y: this.notAnswered(), color: "#E6E6E6" },
              ],
            },
          ],
        }}
      ></ReactHighcharts>
    );
  }
}
