import React from 'react';

export default class Error extends React.Component {
  render() {
    return <div className='row'>
      <div className='offset-sm-2 col-sm-8 p-5'>
        <h1>Error</h1>
        <h3>
          There was an error loading your report.
          <br />
          Please try refreshing the page.
          <br />
          If the error persists, please email john@leadsigma.com</h3>
      </div>
    </div>
  }
}