import React from "react";
import { Col, Row } from "react-bootstrap";
import { HiCheck } from "react-icons/hi";
import { RiStarFill } from "react-icons/ri";
import Typography from "../../general/Typography/Typography";
import "./CircleGraphs.css";
import blackLogo from "../../../assets/sigma-logo-black.png";
import PercentChart from "./PercentChart";
import { secToMin } from "../../../util";

const CircleGraphs = ({ audit }) => {
  const VOICE = "voice";
  const TEXT = "text";
  const EMAIL = "email";

  const getTime = (type) => secToMin(audit[`first_contact_${type}`]);
  const withinFive = (types) => {
    for (let type of types) {
      if (getTime(type) > 5) return false;
    }
    return true;
  };
  const getResponses = (type) => audit[`total_${type}_messages`];
  const hasResponse = (types) => {
    for (let type of types) {
      if (getResponses(type) === 0) return false;
    }
    return true;
  };
  const firstResponseHour = () => {
    const minMinute = secToMin(
      Math.min([
        audit.first_contact_voice,
        audit.first_contact_email,
        audit.first_contact_text,
      ])
    );
    return Math.ceil(minMinute / 60);
  };
  const withinHours = (firstTime, hour) => {
    return firstTime <= hour;
  };

  const getImmediatePercent = () => {
    if (!audit) return 0;
    if (withinFive([VOICE]) && !hasResponse([EMAIL, TEXT])) return 6;
    if (withinFive([VOICE, TEXT])) return 2;
    if (withinFive([VOICE, TEXT, EMAIL])) return 0;
    if (withinFive([TEXT]) && !withinFive([VOICE, EMAIL])) return 17;
    if (withinFive([EMAIL]) && !withinFive([VOICE, TEXT])) return 19;
    if (withinFive([EMAIL, TEXT]) && !withinFive([VOICE])) return 13;
    if (!hasResponse([VOICE, TEXT, EMAIL])) return 46;

    const first = firstResponseHour();
    if (withinHours(first, 3)) return 21;
    if (withinHours(first, 6)) return 27;
    if (withinHours(first, 12)) return 31;
    if (withinHours(first, 24)) return 33;
    if (withinHours(first, 48)) return 37;
    return 39;
  };

  const getFrequentPercent = () => {
    if (!audit) return 0;
    if (audit.total_responses === 0) return 46;
    if (audit.total_responses === 1) return 36;
    if (audit.total_responses === 2) return 24;
    if (audit.total_responses === 3) return 21;
    if (audit.total_responses === 4) return 14;
    if (audit.total_responses === 5) return 7;
    return 2;
  };

  return (
    <Row className="circle-graph-container">
      <Row className="black-check-txt mt-3 ">
        <Col
          xs={12}
          lg={4}
          className="d-flex align-items-center justify-content-center  px-0"
        >
          <HiCheck size={"3em"} className="mr-2" />
          <Typography size="medium">Effortless onboarding</Typography>
        </Col>
        <Col
          xs={12}
          lg={4}
          className="d-flex align-items-center justify-content-center  px-0"
        >
          <HiCheck size={"3em"} className="mr-2" />
          <Typography size="medium">Dedicated account rep</Typography>
        </Col>
        <Col
          xs={12}
          lg={4}
          className="d-flex align-items-center justify-content-center  px-0"
        >
          <HiCheck size={"3em"} className="mr-2" />
          <Typography size="medium">5x ROI guaranteed</Typography>
        </Col>
        <Col
          xs={12}
          className="d-flex justify-content-center align-items-center mt-3 mb-5"
        >
          <RiStarFill size={"2em"} className="star" />
          <RiStarFill size={"2em"} className="star" />
          <RiStarFill size={"2em"} className="star" />
          <RiStarFill size={"2em"} className="star" />
          <RiStarFill size={"2em"} className="star mr-3" />
          <Typography size="medium">based on public reviews</Typography>
        </Col>
      </Row>
      <hr className="black-line-break" />
      <Row className="d-flex justify-content-center mt-5">
        <Col xs={12} className="d-flex justify-content-center">
          <img src={blackLogo} alt="LeadSigma Black Logo" />
        </Col>
        <Col xs={12} className="d-flex justify-content-center">
          <Typography
            size="large"
            className="pl-3 pl-sm-o mobile-center-text data-text"
          >
            data analysts estimate that you are:
          </Typography>
        </Col>
        <Row className="chart-section">
          <Col xs={12} xl={6} className="d-flex flex-column align-items-center">
            <PercentChart percent={getImmediatePercent()} />
            <div className="percent-text">
              <Typography size="large">
                Losing{" "}
                <Typography
                  size="xlarge"
                  color="red"
                >{`${getImmediatePercent()}%`}</Typography>{" "}
                of potential revenue by lack of immediate follow-up.
              </Typography>
            </div>
          </Col>
          <Col xs={12} xl={6} className="d-flex flex-column align-items-center">
            <PercentChart percent={getFrequentPercent()} />
            <div className="percent-text">
              <Typography size="large">
                Losing{" "}
                <Typography
                  size="xlarge"
                  color="red"
                >{`${getFrequentPercent()}%`}</Typography>{" "}
                of potential revenue by lack of frequent follow-up.
              </Typography>
            </div>
          </Col>
        </Row>
      </Row>
    </Row>
  );
};

export default CircleGraphs;
