import React from "react";
import { withRouter } from "react-router-dom";
import ReactGA from "react-ga";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Calendly from "./Calendly.js";
import Competition from "./Competition.js";
import FeatureList from "./FeatureList.js";
import TestimonialList from "./TestimonialList.js";
import Header from "./Header.js";
import Score from "./Score.js";
import Error from "./Error.js";
import Overview from "./Overview.js";
import LeadList from "./LeadList.js";

class Company extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isError: false,
      company: null,
      formSubmissionList: null,
      competitionList: [],
    };
  }
  googleAnalytics() {
    ReactGA.initialize("UA-178242247-1");
  }

  loadCompany(website) {
    fetch(`${process.env.REACT_APP_API_URL}/companies?website=${website}`)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.length === 0) {
          this.setState({ isLoading: false, isError: true });
        } else {
          this.setState({ company: responseJson[0], isError: false });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false, isError: true });
        console.error(error);
      });
  }

  loadFormSubmissions(company_id) {
    fetch(
      `${process.env.REACT_APP_API_URL}/form_submissions?company_id=${company_id}`
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.length === 0) {
          this.setState({ isLoading: false, isError: true });
        } else {
          const forms = responseJson.filter((f) => {
            return f.form_status.id !== 7;
          });
          this.setState({ formSubmissionList: forms, isLoading: false });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  loadCompetition(company_id) {
    fetch(
      `${process.env.REACT_APP_API_URL}/competitors?company_id=${company_id}`
    )
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({ competitionList: responseJson });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  componentDidMount() {
    if (this.state.company === null) {
      this.googleAnalytics();
      this.loadCompany(this.props.match.params.website);
    }
  }

  componentDidUpdate() {
    if (
      this.state.formSubmissionList === null &&
      this.state.company &&
      !this.state.isError
    ) {
      this.loadFormSubmissions(this.state.company.id);
      this.loadCompetition(this.state.company.id);
    }
  }

  render() {
    if (this.state.isError) {
      return <Error />;
    }
    console.log(this.props);
    if (this.state.isLoading) {
      return (
        <div className="row">
          <div className="col-sm-12 text-center p-5">
            <p>Loading...</p>
            <Loader type="ThreeDots" color="#00BFFF" height={160} width={160} />
          </div>
        </div>
      );
    }

    return (
      <div className="container-fluid">
        <div className="green-background"></div>
        <div className="row justify-content-center">
          <div className="col-sm-10">
            <div className="row">
              <div className="col-sm-12">
                <Header />
              </div>
            </div>
            <div className="row pt-5">
              <div className="col-md-12 col-lg-8">
                <Overview
                  company={this.state.company.name}
                  time={this.state.formSubmissionList[0]?.submitted_at}
                  formSubmissionList={this.state.formSubmissionList}
                />
                <LeadList formSubmissionList={this.state.formSubmissionList} />
                <Competition
                  company={this.state.company}
                  competitionList={this.state.competitionList}
                />
                <FeatureList />
                <TestimonialList />
                <Calendly />
              </div>
              <div className="col-md-12 col-lg-4">
                <Score
                  formSubmissionList={this.state.formSubmissionList}
                  competitionList={this.state.competitionList}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Company);
