import React from "react";
import "./Header.css";
import logo from "../../assets/Logo.png";
import { Row, Col } from "react-bootstrap";
import DemoButton from "../general/DemoButton";
const Header = () => {
  return (
    <Row className="header ">
      <Row className="header-container">
        <Col className="logo-container h-100 p-2">
          <a href="https://www.leadsigma.com/" className="logo-link">
            {" "}
            <img src={logo} alt="LeadSigma Logo" className="img-logo" />
          </a>
        </Col>

        <Col className="header-btn-container px-0">
          <DemoButton className="px-0 px-md-2" />
        </Col>
      </Row>
    </Row>
  );
};
export default Header;
