import React from 'react';

export default class EmailRecommendations extends React.Component {
  render() {
    return <div className='mt-3'>
      <div className='bg-recommendations border rounded pt-4 pb-4 pl-1 row'>
        <div className='col-sm-12'>
          <div className='row'>
            <div className='col-sm-12'>
              <img src='/img/star.svg' className='float-left pr-1' alt='Recommendation' />
              <span>
                Recommendation
              </span>
            </div>
          </div>

          <div className='row pt-3'>
            <div className='col-sm-12'>
              <ul style={{marginBottom: '0px'}}>
                <li className='text-muted'>
                  Start using a clear call-to-action in your email messages.
                </li>
                <li className='text-muted'>
                  Increase the frequency of email attempts over the first week.
                </li>
                <li className='text-muted'>
                  Keep email short, concise, and action oriented.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  }
}