import React from 'react';

export default class Testimonial extends React.Component {
  render() {
    return <div className='container'>
      <div className='row'>
        <div className='col text-center p-3'>
          <div>
            <img src={process.env.PUBLIC_URL + `/img/${this.props.image}`}
                 className="img-fluid rounded-circle"
                 alt={this.props.name}
                 style={{maxWidth: '150px'}} />
          </div>
          <div className='pt-5 text-center'>
            <span className='font-weight-bold'>{this.props.name}</span><br />
            <span className='text-muted'>{this.props.title}</span><br />
           </div>
        </div>
      </div>
      <div className='row'>
        <div className='col text-center'>
          <img src='/img/quotes.png' alt='quotes' className='text-image-overlay ml-4' />
          <span className='text-muted'>{this.props.message}</span>
        </div>
      </div>
    </div>
  }
}