import React from 'react';
import GoogleMapReact from 'google-map-react';
import './Competition.css';

export default class Competition extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isError: false,
      competitionList: []
    };
  }

  renderMarkers(map, maps) {
    if (!this.props.competitionList) {
      return
    }

    this.props.competitionList.slice(0, 11).forEach((competitor, index) => {
      new maps.Marker({
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
        map,
        animation: maps.Animation.DROP,
        position: { lat: competitor.latitude, lng: competitor.longitude },
        radius: 700
      });
    });
  }

  center() {
    return {
      lat: this.props.company.latitude,
      lng: this.props.company.longitude
    }
  }

  isLoading() {
    const companyLoaded = typeof this.props.company == undefined
    const competitorsLoaded = typeof this.props.competitionList == undefined
    return companyLoaded && competitorsLoaded
  }

  competitorColor(index) {
    if (index === 0) {
      return 'col-sm-10 bg-green text-white rounded p-2'
    }
    if (index === 1) {
      return 'col-sm-10 bg-purple text-white rounded p-2'
    }
    if (index === 2) {
      return 'col-sm-10 bg-blue text-white rounded p-2'
    }
  }

  topThreeCompetitors() {
    if (!this.props.competitionList) {
      return
    }

    return this.props.competitionList.slice(0, 3).map((competitor, index) => {
      const colorClass = this.competitorColor(index)
      return <div className='col-sm-4 p-3' key={index}>
        <div className='row' style={{height: '100%'}}>
          <div className={colorClass}>
            #{index + 1}
            <br />{competitor.name}
            <br /><small>{competitor.city}, {competitor.state}</small>
          </div>
        </div>
      </div>
    })
  }

  getCompClass(competitor) {
    if (competitor.is_company) {
      return 'p-2 pb-1 pt-1 text-truncate border rounded border-primary bg-light'
    }
    return 'pb-1 pt-1 text-truncate'
  }

  nextFourCompetitors() {
    if (!this.props.competitionList) {
      return
    }

    return this.props.competitionList.slice(3, 7).map((competitor, index) => {
      return <div key={index}>
          <div className={this.getCompClass(competitor)}>
          <span className='font-weight-bold text-14'>#{index + 4} {competitor.name}</span><br />
          <span className='text-muted text-12'>{competitor.city}, {competitor.state}</span>
        </div>
        <hr />
      </div>
    })
  }

  lastFourCompetitors() {
    if (!this.props.competitionList) {
      return
    }

    return this.props.competitionList.slice(7, 11).map((competitor, index) => {
      return <div key={index}>
        <div className={this.getCompClass(competitor)} >
          <span className='font-weight-bold text-14'>#{index + 8} {competitor.name}</span><br />
          <span className='text-muted text-12'>{competitor.city}, {competitor.state}</span>
        </div>
        <hr />
      </div>
    })
  }

  render() {
    if (this.isLoading() || this.props.competitionList.length <= 5) {
      return <div></div>
    }

    return <div className='pt-5'>

      <div className='row pb-3'>
        <div className='col-sm-12'>
          <h4>Competitors</h4>
        </div>
      </div>
      <div className='row border rounded'>
        <div className='col-xl-6 col-lg-12 p-5'>
          <div className='row'>
            <div className='col-sm-12'>
              <span className='text-muted'>We also shopped the average lead response time of ten local competitors using the same methodology.  Here's how you compare:</span>
            </div>
          </div>
          <div className='row pl-3 p-3'>
            <div className='col-sm-12'>
              <div className='row pt-2 mb-2'>
                {this.topThreeCompetitors()}
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-6'>
              {this.nextFourCompetitors()}
            </div>
            <div className='col-sm-6'>
              {this.lastFourCompetitors()}
            </div>
          </div>
        </div>
        <div className='col-xl-6 col-lg-12 map-container-parent'>
              <div className='map-container'>
          <GoogleMapReact
            yesIWantToUseGoogleMapApiInternals={true}
            bootstrapURLKeys={{ key: process.env.REACT_APP_MAP_KEY }}
            defaultCenter={this.center()}
            defaultZoom={10}
            onGoogleApiLoaded={({map, maps}) => this.renderMarkers(map, maps)}
            options={{
              styles: [
                {
                  featureType: "landscape",
                  elementType: "geometry",
                  stylers: [{ color: "#E8E8E8" }],
                },
                {
                  featureType: "poi",
                  elementType: "geometry",
                  stylers: [{ color: "#E8E8E8" }],
                },
                {
                  featureType: "road",
                  elementType: "geometry",
                  stylers: [{ color: "#FFFFFF" }],
                },
                {
                  featureType: "road",
                  elementType: "geometry.stroke",
                  stylers: [{ color: "#FFFFFF" }],
                },{
                  featureType: "road.highway",
                  elementType: "geometry",
                  stylers: [{ color: "#a7c2fa" }],
                },
                {
                  featureType: "road.highway",
                  elementType: "geometry.stroke",
                  stylers: [{ color: "#7ca1f1" }],
                },
              ]
            }}
          >
          </GoogleMapReact>
          </div>
        </div>
      </div>
    </div>
  }
}