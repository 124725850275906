import React from "react";
import sigma from "../assets/sigma-benefits.png";

const SigmaImage = () => {
  return (
    <div className="sigma-image-container mt-5 mb-5">
      <img src={sigma} alt="LeadSigma Benefits Chart" className="sigma-image" />
    </div>
  );
};

export default SigmaImage;
