import React from 'react';
import Scrollchor from 'react-scrollchor';


export default class Header extends React.Component {
  render() {
    return <div className='row'>
        <div className='col-sm-12'>
          <nav className="navbar">
            <span className="navbar-brand">
              <strong>LeadSigma</strong>
            </span>
            <ul className="navbar-nav ml-auto">
              <li className="nav-item active">
                <Scrollchor to="schedule" className="nav-link">
                  <strong>Schedule Demo</strong>
                </Scrollchor>
              </li>
            </ul>
          </nav>
        </div>
      </div>
  }
}
