import React from 'react';

export default class FeatureList extends React.Component {
  render() {
    return <div className='mt-5'>

      <div className='row'>
        <div className='col-sm-12'>
          <h4>How it Works</h4>
        </div>
      </div>
    <div className='row mt-3'>
      <div className='col-sm-12 p-5 border rounded'>
      <div className='row pb-4'>
        <div className='col-sm-10'>
          <div className='row'>
            <div className='col-sm-2 text-center p-2'>
              <img src={process.env.PUBLIC_URL + '/img/how-step1.svg'} className="img-fluid" alt="" />
            </div>
            <div className='col-sm-10'>
              <span className='font-weight-bold'>Immediately Connect with Prospects</span><br />
              <span className='text-muted'>As soon as a lead is generated, we'll call you using our proprietary <strong>CallSlingshot™</strong> and give you an automated brief of all the important information about the lead.</span>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-sm-10'>
          <div className='row pb-4'>
            <div className='col-sm-2 text-center p-2'>
              <img src={process.env.PUBLIC_URL + '/img/how-step2.svg'} className="img-fluid" alt="" />
            </div>
            <div className='col-sm-10'>
              <span className='font-weight-bold'>Create Consistent Sales Actions</span><br />
              <span className='text-muted'>Systematize your sales process with a new sales task list generated daily to ensure you're following-up with leads</span>
            </div>
          </div>
          <div className='row pb-4'>
            <div className='col-sm-2 text-center p-2'>
              <img src={process.env.PUBLIC_URL + '/img/how-step3.svg'} className="img-fluid" alt="" />
            </div>
            <div className='col-sm-10'>
              <span className='font-weight-bold'>Track Your Sales Activity</span><br />
              <span className='text-muted'>Your realtime dashboard shows the current status of all prospects, ensuring your sales efforts are focused on driving new revenue.</span>
            </div>
          </div>
        </div>
        <div className='col-sm-2 d-none d-sm-block p-2'>
          <img src={process.env.PUBLIC_URL + '/img/phone-mockup.png'} className="img-fluid" alt="" width='120px' />
        </div>
      </div>
      <div className='row'>
        <div className='col-sm-10'>
          <div className='row'>
            <div className='col-sm-2 text-center p-2'>
              <img src={process.env.PUBLIC_URL + '/img/how-step4.svg'} className="img-fluid" alt="" />
            </div>
            <div className='col-sm-10'>
              <span className='font-weight-bold'>Optimize and Accelerate</span><br />
              <span className='text-muted'>LeadSigma's smart AI technology refines your sales communication by suggesting improvements to language and tone.</span>
            </div>
          </div>
        </div>
        </div>
    </div>
    </div>
    </div>
  }
}