import React from "react";
import { Button } from "react-bootstrap";
import Typography from "./Typography/Typography";

const DemoButton = ({ className }) => {
  const scrollToBottom = () => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  };

  return (
    <Button onClick={scrollToBottom} className={`btn-sigma w-100 ${className}`}>
      <Typography color="white" size="small">
        Book a Demo
      </Typography>
    </Button>
  );
};

export default DemoButton;
