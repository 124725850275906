import React from 'react';
import Scrollchor from 'react-scrollchor';
import './Score.css';

export default class Score extends React.Component {
  //position-fixed for affix to top

  // Take an average of each form submission response for the entire company based on the field
  averageResponseMinutes(field) {
    const total_response_seconds = this.props.formSubmissionList.reduce(
      function(sum, value) {
        if (value.audit) {
          return sum + value.audit[field];
        } else {
          return sum;
        }
      }, 0);

    const total_responses = this.props.formSubmissionList.reduce(
      function(sum, value) {
        if (value.audit && value.audit[field]) {
          return sum + 1;
        }
        return sum;
      }, 0);

    if (total_responses === 0) {
      return null
    }
    return ((total_response_seconds / total_responses) / 60.0).toFixed(1);
  }
  
  averagePhoneResponseMinutes() {
    const minutes = this.averageResponseMinutes('first_contact_voice')
    if (!minutes) {
      return
    }
    return Math.ceil(minutes);
  }

  backgroundColorClass(time) {
    let cls = 'row p-3 mb-3 '
    if (time <= 5) {
      cls = cls + 'bg-above-average'
    }

    if (time > 5 && time <= 60) {
      cls = cls + 'bg-average'
    }

    if (time > 60 || !time) {
      cls = cls + 'bg-below-average'
    }
    return cls
  }

  labelIconFile(time) {
    let icons = {
      belowAverage: '/img/below-average',
      average: '/img/average',
      aboveAverage: '/img/above-average'
    }

    if (!time) {
      icons.belowAverage = icons.belowAverage + '.svg'
      icons.average = icons.average + '-inactive.svg'
      icons.aboveAverage = icons.aboveAverage + '-inactive.svg'
    }

    if (time <= 5) {
      icons.belowAverage = icons.belowAverage + '-inactive.svg'
      icons.average = icons.average + '-inactive.svg'
      icons.aboveAverage = icons.aboveAverage + '.svg'
    }

    if (time > 5 && time <= 60) {
      icons.belowAverage = icons.belowAverage + '-inactive.svg'
      icons.average = icons.average + '.svg'
      icons.aboveAverage = icons.aboveAverage + '-inactive.svg'
    }

    if (time > 60) {
      icons.belowAverage = icons.belowAverage + '.svg'
      icons.average = icons.average + '-inactive.svg'
      icons.aboveAverage = icons.aboveAverage + '-inactive.svg'
    }
    return icons
  }

  getCompClass(competitor, index) {
    if (competitor.is_company) {
      return 'font-weight-bold text-14'
    }
    if (Math.abs(this.position() - this.start() - index) === 1) {
      return 'text-alpha-2 text-14'
    }
    return 'text-alpha-1 text-14'
  }

  position() {
    let position = -1
    this.props.competitionList.forEach((company, index) => {
      if (company.is_company) {
        position = index
      }
    })
    return position
  }

  start() {
    if (this.position() <= 2) {
      return 0
    }
    return this.position() - 2
  }

  end() {
    if (this.position() <= 2) {
      return 5
    }
    return this.position() + 3
  }

  responseTime() {
    if (!this.averagePhoneResponseMinutes()) {
      return <div className='col-sm-3 p-4 text-center'>
        <span className='text-muted'><small>No Phone Response</small></span>
      </div>
    }

    return <div className='col-sm-3 p-4 text-center'>
      <h4 style={{marginBottom: '-5px'}}>
        {this.averagePhoneResponseMinutes().toLocaleString()}
      </h4>
      <span className='text-muted'><small>Minutes</small></span>
    </div>
  }

  rank(index) {
    const position = this.position()
    if (position < 2) {
      return position + index + 1;
    }
    return this.position() + (index - 1)
  }

  nearestCompetition() {
    if (this.props.competitionList.length <= 5) {
      return
    }

    return <div className='row p-4'>
      <div className='col-sm-12'>
        <p className='line-height-1'>
          <small>
            <strong>
              Here's where you rank among ten local competitors
            </strong>
          </small>
        </p>
        {
          this.props.competitionList.slice(this.start(), this.end()).map((competitor, index) => {
            return <span key={index}>
              <span className={this.getCompClass(competitor, index)}>
                #{this.rank(index)} {competitor.name}
              </span>
              <hr className='small-hr' />
            </span>
          })
        }
      </div>
    </div>
  }

  render() {
    const time = this.averagePhoneResponseMinutes();
    const cls = this.backgroundColorClass(time);
    const icons = this.labelIconFile(time);
    return <div className='container rounded-white-box border rounded affix'>
      <div className={cls}>
        {this.responseTime()}
        <div className='col-sm-1 mt-3 mb-3 middle-gray-line'></div>
        <div className='col-sm-6 pt-3 pb-3' style={{lineHeight: '1'}}>
          <strong>Below Average</strong><br />
          <div className='mt-1 text-muted'>
            <small>Your average response time for a high quality lead</small>
          </div>
        </div>
      </div>

      <div className='row justify-content-center pb-4' style={{marginTop: '-35px'}}>
        <div className='col-3 text-center'>
           <img src={process.env.PUBLIC_URL + icons.belowAverage} alt='' />
        </div>
        <div className='col-3 text-center'>
           <img src={process.env.PUBLIC_URL + icons.average} alt='' />
        </div>
        <div className='col-3 text-center'>
           <img src={process.env.PUBLIC_URL + icons.aboveAverage} alt='' />
        </div>
      </div>

      {this.nearestCompetition()}

      <div className='row p-2'>
        <div className='col-sm-12'>
          <p className='line-height-1'>
            <small>You're leaving $1,000s on the table.  Learn how we solve speed-to-lead with LeadSigma.</small>
          </p>
          <br />
        </div>
      </div>
      <div className='row pb-4'>
        <div className='col-sm-12 text-center'>
          <Scrollchor to="schedule" className="btn btn-primary btn-block">Schedule a Free Demo</Scrollchor>
        </div>
      </div>
    </div>
  }
}
