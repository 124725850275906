import React from 'react';
import Testimonial from './Testimonial.js';

export default class TestimonialList extends React.Component {
   testimonials() {
     return [{
       image: 'jo-hansen.webp',
       name: 'Dr. Jo Hansen',
       title: 'Orthodontist',
       message: 'As an orthodontist, it is difficult to monitor and generate useful information about our new patient follow-up.  LeadSigma solves this problem for business owners, so that they know who’s been contacted, and when. Highly recommend!'
     }, {
       image: 'tim.webp',
       name: 'Tim New',
       title: 'Owner, OnsiteLogic ',
       message: 'LeadSigma is changing the game for sales pipeline management.  Many other pipeline management solutions are too complicated and cumbersome, making them a bear to use.  Not LeadSigma! '
     }, {
       image: 'julie.webp',
       name: 'Julie Sczerbinski',
       title: 'Owner, Unearth',
       message: 'LeadSigma is an amazing tool for small business owners who want more sales. Call Slingshot and LeadSigma app make it easy to contact leads quicker and close more deals. '
     }] 
   }

  render() {
    return <div className='mt-5'>

      <div className='row'>
        <div className='col-sm-12'>
          <h4>What Our Clients Say</h4>
        </div>
      </div>
      <div className='row p-3 border rounded mt-3'>
      {
        this.testimonials().map(function(item, i){
          return <div className='col-sm-4' key={i}>
            <Testimonial name={item.name} title={item.title} message={item.message} image={item.image}/>
          </div>
        })
      }
      </div>
    </div>
  }
}