import React from 'react';
import VoicemailPlayer from './VoicemailPlayer.js';

const TranscriptType = {
  Email: 1,
  SMS: 2,
  Phone: 3
}

class TranscriptList extends React.Component {
  constructor(props) {
    super(props)

    this.title = this.title.bind(this);
    this.logoUrl = this.logoUrl.bind(this);
    this.messages = this.messages.bind(this);
    this.responseDate = this.responseDate.bind(this);
    this.formatMessage = this.formatMessage.bind(this);
  }

  logoUrl() {
    const urls = {
      1: 'email',
      2: 'sms',
      3: 'phone'
    }
    return `/img/${urls[this.props.type]}.png`
  }

  title() {
    const titles = {
      1: 'Email',
      2: 'SMS',
      3: 'Phone'
    }
    return titles[this.props.type]
  }

  action() {
    const actions = {
      1: 'Email',
      2: 'Text',
      3: 'Call'
    }
    return actions[this.props.type]
  }

  messageKey() {
    const keys = {
      1: 'email_messages',
      2: 'text_messages',
      3: 'voice_messages'
    }
    return keys[this.props.type]
  }

  emptyMessageSubject() {
    const subjects = {
      1: 'No email communication was sent',
      2: 'No text communication was sent',
      3: 'Lead did not receive a voicemail'
    }
    return subjects[this.props.type]
  }

  emptyMessageBody() {
    const bodies = {
      1: 'Start using short, concise, action oriented emails as an additional touchpoint to contact prospects',
      2: 'Start using text messages as an additional touchpoint to contact prospects immediately with a clear call-to-action',
      3: 'Start calling your contacts immediately when they are at peak interest for your services '
    }
    return bodies[this.props.type]
  }

  missingMessageSubject() {
    const subjects = {
      1: 'No email communication was sent',
      2: 'No text communication was sent',
      3: 'Lead did not receive a phone call'
    }
    return subjects[this.props.type]
  }

  missingMessageBody() {
    const bodies = {
      1: 'Start using short, concise, action oriented emails as an additional touchpoint to contact prospects',
      2: 'Start using text messages as an additional touchpoint to contact prospects immediately with a clear call-to-action',
      3: 'Start calling your prospects immediately when they are at peak interest for your services.'
    }
    return bodies[this.props.type]
  }

  responseDate(dateString) {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const months = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    // Monday, August 3rd at 2pm EDT
    const date = new Date(dateString);
    const hour = (date.getHours() + 24) % 12 || 12;
    const minutes = `${date.getMinutes()}`.padStart(2, '0')
    const amPm = date.getHours() >= 12 ? 'pm' : 'am';
    const timezone = new Date().toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2];
    const day = date.getDate();
    const ordinal = day + (day > 0 ? ['th', 'st', 'nd', 'rd'][(day > 3 && day < 21) || day % 10 > 3 ? 0 : day % 10] : '');
    return `${days[date.getDay()]}, ${months[date.getMonth()]} ${ordinal} ${hour}:${minutes}${amPm} ${timezone}`;
  }

  formatMessage(message) {
    const content = (message.content) ? message.content : message.body;
    if (!content && !message.recording) {
      return <div>
        <div className='font-weight-bold'>{this.emptyMessageSubject()}</div>
        <div className='text-muted pt-2'>{this.emptyMessageBody()}</div>
      </div>
    }
    if (this.props.type === 3) {
      return <VoicemailPlayer recording={message.recording} />
    }
    return <span className='text-muted' style={{whiteSpace: 'pre-line'}}>
      {content.replace(/\n\s*\n/g, '\n')}
    </span>
  }

  messages(list, index) {
    if (!list || list === null || list.length === 0) {
      return <div className='col-sm-12 bg-light p-4 mt-3'>
        <div className='font-weight-bold'>{this.missingMessageSubject()}</div>
        <div className='text-muted pt-2'>{this.missingMessageBody()}</div>
      </div>
    }

    return list.map((message, index) => {
      return <div key={index} className='col-sm-12 bg-light p-4 mt-3'>
        <div className='row'>
          <div className='col-sm-6 text-truncate'>
            <b>From {message.from}</b>
          </div>
          <div className='col-sm-6 text-right text-truncate'>
            <p><small>{this.responseDate(message.created_at)}</small></p>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-12 text-truncate'>
            {this.formatMessage(message)}
          </div>
        </div>
      </div>
    })
  }

  render() {
    const formSubmissionList = this.props.formSubmissionList || [];

    return <div className='pt-3'>
      {
        formSubmissionList.map((formSubmission, index) => {
          return <div key={index} className='row pt-3'>
            {this.messages(formSubmission[this.messageKey()], index)}
          </div>
        })
      }
    </div>
  }
}

export {TranscriptType, TranscriptList}