import React from 'react';
import ReactGA from 'react-ga';
import ReactAudioPlayer from 'react-audio-player';

export default class VoicemailPlayer extends React.Component {
  didPlay() {
    ReactGA.event({
      category: 'User',
      action: 'Played voicemail audio'
    });
  }

  render() {
    return <div className='row mb-3'>
        <ReactAudioPlayer
          className='col-sm-12 pt-3'
          src={this.props.recording}
          controls
          onPlay={e => this.didPlay()}
        />
    </div>
  }
}
