import React from 'react';
import {Row, Col, ProgressBar} from 'react-bootstrap';

const ResponseType = {
  Email: 1,
  SMS: 2,
  Phone: 3
}

class ResponseTime extends React.Component {
  getIcon(type) {
    const icons = {
      1: '/img/solid-black-email.svg',
      2: '/img/solid-black-text.svg',
      3: '/img/solid-black-phone.svg'
    }
    return icons[type]
  }

  getTitle(type) {
    const titles = {
      1: 'Email',
      2: 'SMS',
      3: 'Phone'
    }
    return titles[type]
  }

  getRecommendedTime(type) {
    const times = {
      1: '1',
      2: '1',
      3: '5'
    }
    return times[type]
  }

  getTime() {
    if (this.props.time === null) {
      return 'Never'
    }
    const minutes = Math.ceil(this.props.time / 60);
    return `${minutes.toLocaleString()} mins`;
  }

  getProgressBarTime() {
    if (this.props.time === null) {
      return '60'
    }
    const minutes = this.props.time / 60;
    if (minutes > 60) {
      return '55'
    }
    return minutes;
  }

  getVariant() {
    const time = this.props.time;
    if (!time) {
      return 'danger'
    }

    const minutes = this.props.time / 60;
    if (minutes < 5) {
      return 'success'
    }
    if (minutes >=5 && minutes < 60) {
      return 'warning'
    }
    if (minutes >= 60) {
      return 'danger'
    }
  }

  getIndicatorIcon() {
    const time = this.props.time;
    if (!time) {
      return '/img/red-circle.svg'
    }

    const minutes = time / 60;
    if (minutes >= 60) {
      return '/img/red-circle.svg'
    }
    if (minutes < 5) {
      return '/img/green-check.svg'
    }
    if (minutes > 5 && minutes < 60) {
      return '/img/yellow-circle.svg'
    }
  }

  render() {
    return <div>
      <Row className='pt-4'>
        <Col>
          <img src={this.getIndicatorIcon()} alt='response time status' width='24' height='24' className='mb-1' style={{marginLeft: '-36px'}} />
          <img src={process.env.PUBLIC_URL + this.getIcon(this.props.type)} alt='response time' className='pl-1 pr-1 pb-1' />
          <span className='text-18 font-weight-bold'>{this.getTitle(this.props.type)} Response Time</span><br />
          {this.getTime()}<br />
        </Col>
      </Row>
      <Row className='pt-4'>
        <Col>
          <span className='text-muted'>You</span><br />
        </Col>
        <Col className='text-right'>
          <strong>{this.getTime()}</strong>
        </Col>
      </Row>
      <Row>
        <Col>
          <ProgressBar now={this.getProgressBarTime()} variant={this.getVariant()} max='60' />
        </Col>
      </Row>
      <Row className='pt-4 pb-2'>
        <Col>
          <span className='text-muted'>Average of fastest growing businesses</span>
        </Col>
        <Col className='text-right'>
          <strong>{this.getRecommendedTime(this.props.type)} mins</strong>
        </Col>
      </Row>
      <Row>
        <Col>
          <ProgressBar now={this.getRecommendedTime(this.props.type)} variant="success" />
        </Col>
      </Row>
    </div>
  }
}

export {ResponseType, ResponseTime}
// Phone = 5, Email, Text = 1 minute