import React from 'react';
import './Calendly.css';

export default class Calendly extends React.Component {
  componentDidMount() {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute('src',  'https://assets.calendly.com/assets/external/widget.js');
    head.appendChild(script);
  }

  render() {
    return <div className='container mt-5'>
      <div className='row'>
        <div className='col-sm-12'>
          <h4 className='demo-title' id='schedule'>
            Schedule a Demo
          </h4>
        </div>
        <div className='col-sm-12'>
          <div className='calendly-inline-widget'
               data-url='https://calendly.com/leadsigma/leadsigma-demo'>
          </div>
        </div>
      </div>
    </div>
  }
}