import React from "react";
import ReactGA from "react-ga";
import ReactAudioPlayer from "react-audio-player";

const VoicemailPlayer = ({ recording, autoPlay }) => {
  const didPlay = () => {
    ReactGA.event({
      category: "User",
      action: "Played voicemail audio",
    });
  };
  return (
    <div className="row mb-3">
      <ReactAudioPlayer
        className="col-sm-12 pt-3"
        src={recording}
        controls
        onPlay={didPlay}
        autoPlay={autoPlay}
      />
    </div>
  );
};

export default VoicemailPlayer;
