import React from 'react';
import ResponseTimeline from './ResponseTimeline.js';
import './Header.css';

export default class Overview extends React.Component {
  constructor(props) {
    super(props);

    this.auditDate = this.auditDate.bind(this);
  }

  auditDate() {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const months = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    const auditDate = new Date(this.props.time);
    const day = auditDate.getDate();
    const ordinal = day + (day > 0 ? ['th', 'st', 'nd', 'rd'][(day > 3 && day < 21) || day % 10 > 3 ? 0 : day % 10] : '');
    return `${days[auditDate.getDay()]}, ${months[auditDate.getMonth()]} ${ordinal}`;
  }

  imageUrl() {
    const lastSubmission = this.props.formSubmissionList.slice(-1)[0]
    if (lastSubmission && lastSubmission.screenshots) {
      const lastScreenshot = lastSubmission.screenshots.slice(-1)[0]
      if (lastScreenshot) {
        return lastScreenshot.image_url
      }
    }
    return '/img/blank-screenshot.png'
  }

  render() {
    return <div>
      <div className='row'>
        <div className='col-sm-10'>
          <img src={this.imageUrl()}
               className='img-fluid'
               alt=''
               style={{marginLeft: '100px'}} />
        </div>
      </div>
      <div className='row screenshot-overlay'>
        <div className='col-sm-12 rounded-white-box border rounded p-4'>
          <h6>Complimentary Secret Shopper Summary</h6>
          <h1>{this.props.company}</h1>
          <p className='text-secondary line-height-1'><small>We secret shopped your lead response time by contacting your business (during business hours) as a new lead starting on {this.auditDate()}. Here's what we found:</small></p>
          <ResponseTimeline lead={this.props.formSubmissionList[0]}/>
        </div>
      </div>
    </div>
  }
}
