import React from 'react';
import Lead from './Lead.js';

export default class LeadList extends React.Component {

  render() {
    return <div>
      <div className='row'>
        <div className='col-sm-12'>
          <h4>
            Lead Analysis
          </h4>
        </div>
      </div>
    {this.props.formSubmissionList.map((lead, i) => 
      <Lead lead={lead} i={i} key={i}/>
    )}
    </div>
  }
}
