import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Gauge from "../../general/Gauge/Gauge";
import Typography from "../../general/Typography/Typography";
import { FaExclamationCircle } from "react-icons/fa";
import "./ContentSection.css";
import { secToMin, formatNumber } from "../../../util";
import GaugeLabel from "../../general/Gauge/GaugeLabel";
import DemoButton from "../../general/DemoButton";

const ContentSection = ({ label, type, audit, children }) => {
  const isExclamation = (type) => {
    if (!audit) return;
    return (
      audit[`total_${type}_messages`] === 0 ||
      secToMin(audit[`first_contact_${type}`]) > 5
    );
  };
  const getResponseTimeText = (type) => {
    if (!audit) return [""];
    if (audit[`total_${type}_messages`] === 0) return ["No Response"];
    return [`${formatNumber(secToMin(audit[`first_contact_${type}`]))} mins`];
  };

  const getGaugeValue = (type) => {
    if (!audit) return 0;
    if (audit[`total_${type}_messages`] === 0) return 60;
    return secToMin(audit[`first_contact_${type}`]);
  };

  const renderFooterMessage = (type) => {
    if (type === "voice")
      return (
        <div>
          <Typography size="medium" style="bold" center>
            Start calling your leads immediately.
          </Typography>
          <Typography size="medium" style="bold" center>
            Never lose another new customer to a competitor.
          </Typography>
        </div>
      );
    if (type === "email")
      return (
        <Typography size="medium" style="bold" center>
          Email automations save you time and help you close more deals.
        </Typography>
      );
    if (type === "text")
      return (
        <div>
          <Typography size="medium" style="bold" center>
            LeadSigma enables businesses to boost conversions by
          </Typography>
          <Typography size="medium" style="bold" center>
            ensuring every inbound lead receives an immediate text.
          </Typography>
        </div>
      );
  };

  return (
    <Row className="content-section">
      <Row className="section-head">
        <Col xs={12} className="d-flex justify-content-center mt-3">
          {isExclamation(type) && (
            <FaExclamationCircle size={"2.5em"} className="exclamation mr-2" />
          )}
          <Typography
            color="white"
            size="large"
          >{`${label} Response Details`}</Typography>
        </Col>
        <hr className="line" />
        <Col xs={12}>
          <Row>
            <Col xs={12} lg={6} className="p-3">
              <Col xs={12}>
                <Row className="justify-content-center">
                  <Typography size="medium" color="white">
                    Your Business
                  </Typography>
                </Row>
              </Col>
              <Col xs={12} className="d-flex justify-content-center">
                <div className="content-gauge-container d-flex justify-content-center">
                  <Gauge id={`your-${type}`} value={getGaugeValue(type)}>
                    <GaugeLabel
                      exclamation={isExclamation(type)}
                      texts={getResponseTimeText(type)}
                    />
                  </Gauge>
                </div>
              </Col>
            </Col>
            <Col xs={12} lg={6} className="p-3">
              <Col xs={12}>
                <Row className="justify-content-center">
                  <Typography
                    size="medium"
                    color="white"
                    className="mobile-center-text"
                  >
                    Average fast growing businesses
                  </Typography>
                </Row>
              </Col>
              <Col xs={12} className="d-flex justify-content-center">
                <div className="content-gauge-container d-flex justify-content-center">
                  <Gauge
                    id={`average-${type}`}
                    value={type === "phone" ? 5 : 1}
                  >
                    <GaugeLabel
                      texts={[`${type === "voice" ? "5 mins" : "1 mins"}`]}
                    />
                  </Gauge>
                </div>
              </Col>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="w-100 m-0 mt-5">{children}</Row>
      <Row className="d-flex justify-content-center w-100">
        <Col xs={12} className="my-3">
          {renderFooterMessage(type)}
        </Col>
        <Col xs={12} className="d-flex justify-content-center">
          <div className="content-demo-container">
            {" "}
            <DemoButton />
          </div>
        </Col>
      </Row>
    </Row>
  );
};

export default ContentSection;
