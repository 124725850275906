import React from "react";
import { Row, Col } from "react-bootstrap";
import { FaExclamationCircle } from "react-icons/fa";
import Typography from "../Typography/Typography";

const GaugeLabel = ({ exclamation, texts }) => {
  return (
    <Row className="d-flex align-items-center">
      {exclamation && (
        <FaExclamationCircle size={"2.5em"} className="exclamation" />
      )}
      <Col className="d-flex flex-column justify-content-center">
        {texts?.map((text, index) => (
          <Typography key={index} color={"white"} size="medium">
            {text}
          </Typography>
        ))}
      </Col>
    </Row>
  );
};

export default GaugeLabel;
