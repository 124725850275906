import React from "react";
import ReactHighcharts from "highcharts-react-official";
import Highcharts from "highcharts";

const PercentChart = ({ percent }) => {
  const chartTitle = `<div class='chart-text'><h4>${percent}%</h4></div>`;
  Highcharts.setOptions({
    lang: {
      thousandsSep: ",",
    },
  });

  return (
    <ReactHighcharts
      highcharts={Highcharts}
      options={{
        credits: {
          enabled: false,
        },
        chart: {
          type: "pie",
          height: 325,
          width: 325,
          align: "center",
        },
        title: {
          text: chartTitle,
          verticalAlign: "middle",
          useHTML: true,
        },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: false,
            },
            borderWidth: 4,
          },
          series: {
            animation: false,
          },
        },
        series: [
          {
            type: "pie",
            name: "Revenue",
            innerSize: "80%",
            data: [
              {
                name: "",
                y: percent,
                color: "red",
              },
              { name: "", y: 100 - percent, color: "#FFE2E2" },
            ],
          },
        ],
      }}
    ></ReactHighcharts>
  );
};

export default PercentChart;
