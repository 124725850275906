import React from "react";
import laptop from "../../../assets/laptop.png";
const FormGIF = ({ screenshot }) => {
  return (
    <div className="laptop-gif-container">
      <img src={laptop} alt="Laptop" className="laptop-img" />
      <div className="gif-container">
        {screenshot && <img src={screenshot} alt="" className="gif" />}
      </div>
    </div>
  );
};

export default FormGIF;
