export const secToMin = (sec) => (sec > 0 ? Math.ceil(sec / 60) : 1);

export const formatNumber = (number) => new Intl.NumberFormat().format(number);

export const getDateString = (date) => {
  return `${new Date(date).toLocaleDateString("en-US", {
    dateStyle: "full",
  })} at ${new Date(date).toLocaleTimeString("en-US", {
    hour12: true,
    hour: "2-digit",
    minute: "2-digit",
    timeZoneName: "short",
  })}`;
};
