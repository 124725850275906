import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import Gauge from "../../general/Gauge/Gauge";
import GaugeLabel from "../../general/Gauge/GaugeLabel";
import Typography from "../../general/Typography/Typography";
import "./GIFSection.css";
import { getDateString, secToMin, formatNumber } from "../../../util";
import FormGIF from "./FormGIF";

const GIFSection = ({ audit, formData }) => {
  const [phones, setPhones] = useState(null);
  const [phoneTime, setPhoneTime] = useState(null);
  const [emails, setEmails] = useState(null);
  const [emailTime, setEmailTime] = useState(null);
  const [texts, setTexts] = useState(null);
  const [textTime, setTextTime] = useState(null);
  useEffect(() => {
    if (audit) {
      setPhones(audit.total_voice_messages);
      setPhoneTime(secToMin(audit.first_contact_voice));
      setTexts(audit.total_text_messages);
      setTextTime(secToMin(audit.first_contact_text));
      setEmails(audit.total_email_messages);
      setEmailTime(secToMin(audit.first_contact_email));
    }
  }, [audit]);
  return (
    <Row className="gif-section d-flex flex-column m-0">
      <Row className="gif-row">
        <Col
          xs={12}
          lg={6}
          className="txt-col d-flex flex-column justify-content-center"
        >
          <Row>
            <Col xs={0} md={1} lg={2} />
            <Col xs={12} md={9}>
              <Row>
                <Col xs={12} className="px-0">
                  <Typography
                    size="medium"
                    color="white"
                    className="w-100 requested-text"
                  >
                    {`${
                      formData &&
                      formData[0]?.shopper?.persona.first_name +
                        " " +
                        formData[0]?.shopper?.persona.last_name
                    } requested service on:`}
                  </Typography>
                </Col>
                <Col xs={12} className="px-0">
                  <Typography
                    size="medium"
                    style="bold"
                    color="white"
                    className="w-100 requested-text"
                  >
                    {`${formData && getDateString(formData[0]?.submitted_at)}`}
                  </Typography>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={0} md={1} lg={2} />
            <Col xs={12} md={10} className="p-0">
              <Typography
                color="green"
                size="xxlarge"
                style="bold"
                className="large-report-text mt-2"
              >
                Your secret shopper recorded the speed, frequency, and content
                of your team’s lead follow-up.
              </Typography>
            </Col>
          </Row>
        </Col>
        <Col
          xs={12}
          lg={6}
          className="d-flex justify-content-center w-100 mt-3 mt-lg-0"
        >
          <FormGIF
            screenshot={formData && formData[0]?.screenshots[0]?.image_url}
          />
        </Col>
      </Row>
      <Row className="gauge-bar p-0">
        <Col xs={12} md={3}>
          <Gauge id="full-phone" value={phones === 0 ? 60 : phoneTime}>
            <GaugeLabel
              exclamation={phones === 0 || phoneTime > 5}
              texts={[
                `${phones} Phone Call${phones === 0 || phones > 1 ? "s" : ""}`,
                phones > 0 &&
                  (phoneTime > 0 ? `${formatNumber(phoneTime)} minute delay` : null),
              ]}
            ></GaugeLabel>
          </Gauge>
        </Col>
        <Col xs={12} md={3}>
          <Gauge id="full-text" value={texts === 0 ? 60 : textTime}>
            <GaugeLabel
              exclamation={texts === 0 || textTime > 5}
              texts={[
                `${texts} Text Message${texts === 0 || texts > 1 ? "s" : ""}`,
                texts > 0 && (textTime > 0 ? `${formatNumber(textTime)} minute delay` : null),
              ]}
            ></GaugeLabel>
          </Gauge>
        </Col>
        <Col xs={12} md={3}>
          <Gauge id="full-email" value={emails === 0 ? 60 : emailTime}>
            <GaugeLabel
              exclamation={emails === 0 || emailTime > 5}
              type="Phone Call"
              texts={[
                `${emails} Email${emails === 0 || emails > 1 ? "s" : ""}`,
                emails > 0 &&
                  (emailTime > 0 ? `${formatNumber(emailTime)} minute delay` : "1 mins"),
              ]}
            ></GaugeLabel>
          </Gauge>
        </Col>
      </Row>
    </Row>
  );
};

export default GIFSection;
