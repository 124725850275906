import React from "react";
import { HiCheck } from "react-icons/hi";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";

import logoDocumentor from "../images/brands/documentor.png";
import logoFishbein from "../images/brands/fishbein.png";
import logoCatipult from "../images/brands/catipult.png";
import logoObie from "../images/brands/obie.png";
import logoKrieger from "../images/brands/krieger.png";

import logoAxia from "../images/brands/axia.png";
import logoReynolds from "../images/brands/reynolds.png";
import logoSaddleCreek from "../images/brands/saddlecreek.png";
import logoBcMechanical from "../images/brands/bc-mech.png";
import logoComfortExperts from "../images/brands/comfort-experts.png";
import Typography from "./general/Typography/Typography";

const Logo = (props) => {
  return <Image src={props.src} fluid />;
};

const SocialProof = () => {
  return (
    <Container>
      <Typography size="xlarge" style="bold" className="join-text" center>
        Join the world's fastest growing private businesses
      </Typography>

      <Row className="d-flex align-items-center mt-3">
        <Col>
          <Logo src={logoDocumentor} />
        </Col>
        <Col>
          <Logo src={logoFishbein} />
        </Col>
        <Col>
          <Logo src={logoCatipult} />
        </Col>
        <Col>
          <Logo src={logoObie} />
        </Col>
        <Col>
          <Logo src={logoKrieger} />
        </Col>
      </Row>
      <Row className="d-flex align-items-center">
        <Col>
          <Logo src={logoAxia} />
        </Col>
        <Col>
          <Logo src={logoReynolds} />
        </Col>
        <Col>
          <Logo src={logoSaddleCreek} />
        </Col>
        <Col>
          <Logo src={logoBcMechanical} />
        </Col>
        <Col>
          <Logo src={logoComfortExperts} />
        </Col>
      </Row>
      <Row className="d-flex justify-content-center mb-5">
        <div>
          <Col xs={12} className="d-flex align-items-center">
            <HiCheck size={"3.5em"} className="green-check" />
            <Typography size="large" style="bold" className="green-check-text">
              Close more deals
            </Typography>
          </Col>
          <Col xs={12} className="d-flex align-items-center ">
            <HiCheck size={"3.5em"} className="green-check" />
            <Typography size="large" style="bold" className="green-check-text">
              Drive down customer acquisition cost
            </Typography>
          </Col>
          <Col xs={12} className="d-flex align-items-center ">
            <HiCheck size={"3.5em"} className="green-check" />
            <Typography size="large" style="bold" className="green-check-text">
              Increase your marketing ROI across all channels
            </Typography>
          </Col>
        </div>
      </Row>
    </Container>
  );
};

export default SocialProof;
