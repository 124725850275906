import React, { useState } from "react";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import InputMask from "react-input-mask";
import Typography from "./general/Typography/Typography";

const ContactForm = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [phone, setPhone] = useState("");
  const [validated, setValidated] = useState(false);

  const toEin = (phone) => {
    return `+1${phone.replace(/[^0-9]/g, "")}`;
  };

  const startSlingshot = () => {
    const redirect =
      `${process.env.REACT_APP_REDIRECT}` +
      `?team_group_id=${process.env.REACT_APP_TEAM_GROUP_ID}` +
      `&provider=redirect` +
      `&redirect_uri=${encodeURIComponent(
        process.env.REACT_APP_CALENDLY_URL
      )}` +
      `&first_name=${encodeURIComponent(firstName)}` +
      `&last_name=${encodeURIComponent(lastName)}` +
      `&phone=${encodeURIComponent(phone)}` +
      `&company_name=${encodeURIComponent(companyName)}`;

    window.location.replace(redirect);
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (form.checkValidity()) {
      startSlingshot();
    }
    setValidated(true);

    return false;
  };

  return (
    <Container className="p-md-4 bg-green mb-5 contact-form ">
      <Row>
        <Col>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group controlId="firstName">
                  <Form.Label className="w-75 mb-0">
                    <Typography size="medium">First Name *</Typography>
                  </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    pattern="\S(.*\S)?"
                    placeholder="First Name"
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group controlId="lastName">
                  <Form.Label className="w-75 mb-0">
                    <Typography size="medium">Last Name *</Typography>
                  </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    pattern="\S(.*\S)?"
                    placeholder="Last Name"
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group controlId="companyName">
              <Form.Label className="w-100 mb-0">
                <Typography size="medium">Company Name *</Typography>
              </Form.Label>
              <Form.Control
                required
                type="text"
                pattern="\S(.*\S)?"
                placeholder="Company Name"
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="phone">
              <Form.Label className="mb-0">
                <Typography size="medium">Phone *</Typography>
              </Form.Label>
              <InputMask
                required
                type="text"
                className="form-control"
                placeholder="Phone"
                mask="(999) 999-9999"
                pattern="^\(\d{3}\)\s\d{3}-\d{4}"
                onChange={(e) => setPhone(toEin(e.target.value))}
              />
            </Form.Group>
            <Typography className="form-contact-text">
              LeadSigma needs the contact information you provide to us to
              contact you about our products and services. You may unsubscribe
              from these communcations at any time.{" "}
            </Typography>
            <Row className="justify-content-md-center mt-3">
              <Col xs={12} md={6}>
                <Button
                  variant="primary"
                  type="submit"
                  block
                  className="btn-purple"
                >
                  <Typography size="medium" color="white">
                    Book a Demo
                  </Typography>
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default ContactForm;
