import React from "react";
import { Row, Col } from "react-bootstrap";
import GaugeChart from "react-gauge-chart";
import "./Gauge.css";

const Gauge = ({ id, children, value }) => {
  return (
    <Row className="justify-content-center w-100">
      <Col xs={12} className="d-flex justify-content-center">
        <GaugeChart
          id={id}
          percent={value / 60 > 1 ? 1 : value / 60}
          animDelay={100}
          nrOfLevels={5}
          arcPadding={0}
          cornerRadius={0}
          style={{
            height: "100%",
            width: "100%",
            maxWidth: "60vw",
          }}
          needleColor="white"
          needleBaseColor="white"
          hideText
        />
      </Col>
      <Col xs={12} className="d-flex justify-content-center">
        {children}
      </Col>
    </Row>
  );
};

export default Gauge;
