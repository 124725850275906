import React from 'react';
import {Button, Collapse} from 'react-bootstrap';
import EmailRecommendations from './EmailRecommendations.js';
import PhoneRecommendations from './PhoneRecommendations.js';
import SmsRecommendations from './SmsRecommendations.js';
import {ResponseType, ResponseTime} from './ResponseTime';
import {TranscriptType, TranscriptList} from './TranscriptList.js';
import ResponseTimeDonut from './ResponseTimeDonut';
import ResponseTimeline from './ResponseTimeline';

export default class Lead extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  responseDate(dateString) {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const months = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    // Monday, August 3rd at 2pm EDT
    const date = new Date(dateString);
    const hour = (date.getHours() + 24) % 12 || 12;
    const minutes = `${date.getMinutes()}`.padStart(2, '0')
    const amPm = date.getHours() >= 12 ? 'pm' : 'am';
    const timezone = new Date().toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2];
    const day = date.getDate();
    const ordinal = day + (day > 0 ? ['th', 'st', 'nd', 'rd'][(day > 3 && day < 21) || day % 10 > 3 ? 0 : day % 10] : '');
    return `${days[date.getDay()]}, ${months[date.getMonth()]} ${ordinal} ${hour}:${minutes}${amPm} ${timezone}`;
  }

  averageResponseMinutes() {
    return this.props.lead.audit.first_contact_voice;
  }

  getCollapseLabel(open) {
    if (!open) {
      return 'Show Full Analysis ↓';
    }
    return 'Hide Full Analysis ↑';
  }

  render() {
    return <div className='row border rounded p-4 mt-3'>
      <div className='col' key={this.props.i}>
        <div className='row'>
        <div className='col-xl-4 col-lg-12'>
          <p>Lead {this.props.i + 1}: {this.props.lead.shopper.persona.first_name} {this.props.lead.shopper.persona.last_name}</p>
        </div>
        <div className='col-xl-8 col-lg-12 text-right'>
          Submitted: {this.responseDate(this.props.lead.submitted_at)}
        </div>
      </div>
        <div className='row pb-4'>
          <div className='col-xl-4 col-lg-12 text-center'>
            <ResponseTimeDonut time={this.averageResponseMinutes()} />
            Phone Response Time
          </div>
          <div className='col-xl-8 col-lg-12'>
            <ResponseTimeline lead={this.props.lead} />
          </div>
        </div>
        <Collapse in={this.state.open}>
          <div id="example-collapse-text" className='row pb-4'>
            <div className='col-sm-12'>
              <ResponseTime type={ResponseType.Phone} time={this.props.lead.audit.first_contact_voice} />
              <TranscriptList type={TranscriptType.Phone} formSubmissionList={[this.props.lead]} />
              <PhoneRecommendations />
              <ResponseTime type={ResponseType.Email} time={this.props.lead.audit.first_contact_email} />
              <TranscriptList type={TranscriptType.Email} formSubmissionList={[this.props.lead]} />
              <EmailRecommendations />
              <ResponseTime type={ResponseType.SMS} time={this.props.lead.audit.first_contact_text} />
              <TranscriptList type={TranscriptType.SMS} formSubmissionList={[this.props.lead]} />
              <SmsRecommendations />
            </div>
          </div>
        </Collapse>
        <hr />
        <div className='row'>
          <div className='col-sm-12 text-right'>
            <Button
              variant='link'
              onClick={() => this.setState({open: !this.state.open})}
              aria-controls="example-collapse-text"
              aria-expanded={this.state.open}
            >
              {this.getCollapseLabel(this.state.open)}
            </Button>
          </div>
        </div>
      </div>
    </div>
  }
}
