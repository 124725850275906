import React from "react";
import "./Typography.css";

const Typography = ({ children, color, size, style, center, className }) => {
  return (
    <div
      className={`typography txt-${color} txt-${size} txt-${style} ${
        center && "txt-center"
      } ${className}`}
    >
      {children}
    </div>
  );
};

export default Typography;
