import React from 'react';
import ReactHighcharts from 'highcharts-react-official';
import Highcharts from 'highcharts';
require("highcharts/modules/timeline")(Highcharts);

export default class ResponseTimeline extends React.Component {
  chartListData() {
    
  }

  chartData() {
    if (!this.props.lead) {
      return []
    }

    const startDate = new Date(this.props.lead.submitted_at);
    const maxDate = new Date(startDate.toDateString())
    maxDate.setDate(startDate.getDate() + 10)

    let data = []
    this.props.lead.voice_messages.forEach(voice => {
      const date = new Date(voice.created_at).getTime()
      if (date > maxDate) {
        return
      }
      data.push({
        x: date,
        name: 'Phone Call',
        label: '',
        description: '',
        marker: {
          symbol: 'url(/img/timeline-phone.svg)',
          height: 32,
          width: 32
        }
      })
    })
    this.props.lead.email_messages.forEach(email => {
      const date = new Date(email.created_at).getTime()
      if (date > maxDate) {
        return
      }
      data.push({
        x: date,
        name: 'Email',
        label: '',
        description: '',
        marker: {
          symbol: 'url(/img/timeline-email.svg)',
          height: 32,
          width: 32
        }
      })
    })
    this.props.lead.text_messages.forEach(text => {
      const date = new Date(text.created_at).getTime()
      if (date > maxDate) {
        return
      }
      data.push({
        x: date,
        name: 'Text Message',
        label: '',
        description: '',
        marker: {
          symbol: 'url(/img/timeline-text.png)',
          height: 32,
          width: 32
        }
      })
    })
    data.push({
      x: startDate.getTime(),
      name: 'New Lead',
      label: '',
      description: '',
      marker: {
        enabledThreshold: 2,
        symbol: 'url(/img/timeline-lead.svg)',
        height: 32,
        width: 32
      }
    })
    // const endDate = new Date(startDate.valueOf())
    // endDate.setDate(startDate.getDate() + 10)
    // data.push({
    //   x: endDate,
    //   name: 'End'
    // })
    return data
  }

  render() {
    if (!this.props.lead) {
      return null
    }

    let data = this.chartData();
    const startDate = new Date(this.props.lead.submitted_at)
    const minDate = new Date(startDate.toDateString())
    minDate.setDate(startDate.getDate())
    const maxDate = new Date(startDate.toDateString())
    maxDate.setDate(startDate.getDate() + 10)
    return <ReactHighcharts highcharts={Highcharts} options={{
      credits: {
        enabled: false
      },
      chart: {
          zoomType: 'x',
          type: 'timeline',
          height: 150
      },
      xAxis: {
        type: 'datetime',
        visible: true,
        gridLineWidth: 1,
        labels: {
          overflow: false,
          staggerLines: 2,
          align: 'right',
          formatter: function() {
            if (this.isFirst) {
              // return 'Day 1'
            }
            if (this.isLast) {
              return 'Day 10'
            }
          }
        },
        min: minDate,
        tickInterval: 86400 * 60 * 24,
        startOnTick: true,
        endOnTick: true,
        max: maxDate,
        zoomEnabled: false
      },
      yAxis: {
        gridLineWidth: 1,
        title: null,
        labels: {
          enabled: false
        }
      },
      legend: {
        enabled: false
      },
      title: {
        text: ''
      },
      series: [{
        animation: {
          defer: 1000
        },
        dataLabels: {
          enabled: true,
          allowOverlap: true,
          animation: {
            defer: 2000
          }
        },
        marker: {
          symbol: 'circle',
        },
        data: data
      }]
    }}></ReactHighcharts>
  }
}